import React, {useState} from 'react';
import {Box, Button, Stack, TextField, Typography} from '@mui/material';
import './App.css';
import GaugeChart from 'react-gauge-chart';
import {useBeerYearsCalculator} from "./beeryears-calculator";

function App() {
    const {totalBeerYears, standardDrinks, calculate} = useBeerYearsCalculator()

    const [volume, setVolume] = useState<number | null>(null);
    const [alcoholPercentage, setAlcoholPercentage] = useState<number | null>(null);
    const [frequencyPerWeek, setFrequencyPerWeek] = useState<number | null>(null);
    const [durationInYears, setDurationInYears] = useState<number | null>(null);

    const handleCalculate = () => {
        if (volume !== null && alcoholPercentage !== null && frequencyPerWeek !== null && durationInYears !== null) {
            calculate(volume, alcoholPercentage, frequencyPerWeek, durationInYears);
            setVolume(null);
            setAlcoholPercentage(null);
            setFrequencyPerWeek(null);
            setDurationInYears(null);
        }
    };

    return (
        <>
            <Stack sx={{marginTop: "2em", padding: "1em", alignItems: "center"}}>
                <Typography align='center' variant="h2">
                    Beer Years Calculator
                </Typography>
                <Typography sx={{marginTop: "2em"}} align='center' variant="h5">
                    <i>This calculator will render a patient's specific risk for systemic disease by calculating the
                        risk score called beer years.
                        A beer year is equivalent to one standard drink per day for one year.
                    </i>
                </Typography>
                <Typography align='center' variant="body1">
                    Instructions: Keep adding all the alcohols consumed to determined the overall beer years
                </Typography>
                <Box component={"div"} sx={{
                    marginTop: {xs: "2em", sm: "5em"},
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Box sx={{width: {xs: "150px", sm: "250px", md: "350px"}}}>
                        <GaugeChart
                            id="total-beer-years-gauge"
                            nrOfLevels={25}
                            percent={totalBeerYears / 1000}
                            textColor="#000"
                            formatTextValue={(value) => `${totalBeerYears.toFixed(2)} Beer Years`}
                        />
                    </Box>
                    <Box sx={{width: {xs: "150px", sm: "250px", md: "350px"}}}>
                        <GaugeChart
                            id="standard-drinks-gauge"
                            nrOfLevels={25}
                            percent={standardDrinks / 1000}
                            textColor="#000"
                            formatTextValue={(value) => `${standardDrinks.toFixed(2)} Standard Drinks`}
                        />
                    </Box>
                </Box>
                <Stack sx={{marginTop: "2em", maxWidth: "30em", alignItems: "center"}} spacing={2}>
                    <TextField type="number" label="Volume (ml)" id="volume" variant="outlined" value={volume ?? ''}
                               onChange={(e) => setVolume(Number(e.target.value))}/>

                    <TextField type="number" label="Alcohol Percentage (%)" id="alcoholPercentage" variant="outlined"
                               value={alcoholPercentage ?? ''}
                               onChange={(e) => setAlcoholPercentage(Number(e.target.value))}/>

                    <TextField type="number" label="Frequency Per Week" id="frequencyPerWeek" variant="outlined"
                               value={frequencyPerWeek ?? ''}
                               onChange={(e) => setFrequencyPerWeek(Number(e.target.value))}/>

                    <TextField type="number" label="Duration In Years" id="durationInYears" variant="outlined"
                               value={durationInYears ?? ''}
                               onChange={(e) => setDurationInYears(Number(e.target.value))}/>

                    <Button variant="contained" onClick={handleCalculate}>Calculate!</Button>
                </Stack>
            </Stack>
        </>
    );
}

export default App;
