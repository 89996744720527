import { useState } from 'react';

function calculateStandardDrinksAndDuration(volume: number, alcoholPercentage: number, frequencyPerWeek: number, durationInYears: number): [number, number] {
    const weeksPerYear = 52.1775; // average number of weeks per year, accounting for leap years
    const totalDrinks = frequencyPerWeek * weeksPerYear;
    const alcoholContent = volume * alcoholPercentage * 0.79 / 100.0;
    const standardDrinksPerYear = totalDrinks * alcoholContent / 10.0; // 10g of pure alcohol per standard drink
    const beerYears = durationInYears * standardDrinksPerYear / 365.25; // 1 beer year = 1 standard drink per day for 1 year
    return [standardDrinksPerYear, beerYears];
}

export function useBeerYearsCalculator() {
    const [totalBeerYears, setTotalBeerYears] = useState(0);
    const [standardDrinks, setStandardDrinks] = useState(0);

    const calculate = (volume: number, alcoholPercentage: number, frequencyPerWeek: number, durationInYears: number) => {
        const [standardDrinksPerYear, beerYears] = calculateStandardDrinksAndDuration(volume, alcoholPercentage, frequencyPerWeek, durationInYears);
        setStandardDrinks(standardDrinksPerYear);
        setTotalBeerYears(prevTotal => prevTotal + beerYears);
    };

    return { totalBeerYears, standardDrinks, calculate };
}